@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html body#root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
  min-height: 100vh;
  overflow: hidden;
}

html.dark body#root {
  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 20, 18, 17;
  --background-end-rgb: 0, 0, 0;

  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
  min-height: 100vh;
  overflow: hidden;
}

/* 
 * here we add some preflight styles to normalize the browser defaults
 */

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

/**
 * embedded widget to Jira Service Desk
 * https://infworks.atlassian.net/jira/servicedesk/projects/RSP/settings/widget
 */
iframe[name="JSD widget"] {
  left: 0px !important;
  bottom: -12px !important;
  right: auto !important;
  top: auto !important;
  z-index: 1300 !important;
}
